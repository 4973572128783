<template>
    <div v-if="visible" :style="menuStyles" class="context-menu" @click="hideMenu">
        <ul>
            <li v-for="(item, index) in menuItems" :key="index" @click="selectItem(item)">
                {{ item.label }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "ContextMenu",
    data() {
        return {
            visible: false,
            menuItems: [],
            menuStyles: {
                top: '0px',
                left: '0px',
            },
        };
    },
    mounted() {
        document.addEventListener('click', this.hideMenu);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideMenu);
    },
    methods: {
        showMenu(event, items) {
            this.menuItems = items;
            this.menuStyles.top = `${event.pageY - 80}px`;
            this.menuStyles.left = `${event.clientX}px`;
            this.visible = true;
        },
        hideMenu() {
            this.visible = false;
        },
        selectItem(item) {
            this.$emit('select', item);
            this.hideMenu();
        },
    },
};
</script>

<style scoped>
.context-menu {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.context-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.context-menu li {
    padding: 8px 8px;
    cursor: pointer;
    width: 150px;
    border-bottom: 1px solid #dadada;
}

.context-menu li:hover {
    background-color: #060f1a;
    color: white;
}
</style>