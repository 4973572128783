<template>
  <div>
    <b-table hover sort-icon-left resizable :items="tableData.dataSource" :fields="tableData.tableColumns"
      :headers="tableData.dataSource.tableColumns" :busy="tableData.isLoading" :per-page="tableData.resultsPerPage"
      :current-page="tableData.currentPage" selectable select-mode="single" @row-selected="handleRowClicked" show-empty>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
        </div>
      </template>
      <template #empty>
        <div class="text-center my-2">
          <span v-if="!tableData.isLoading && tableData.dataSource.length === 0">No results found.</span>
          <span v-else>No data available.</span>
        </div>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(total)="data">
        {{ data.item.financialLines.length }}
      </template>
      <template #cell(orderDate)="data">
        {{ formatDate(data.value) }}
      </template>
      <template #cell(invoiceDate)="data">
        {{ formatDate(data.value) }}
      </template>

      <template #cell(actions)="row">
        <b-row align-v="center" align-h="end">
          <b-button @click="toggleDetails(row.item)" size="sm"
            style="background: transparent; border: none; color: black; box-shadow: none;">
            <b-icon :icon="row.item._showDetails ? 'arrow-up' : 'arrow-down'"></b-icon>
          </b-button>
        </b-row>
      </template>

      <template #row-details="row">
        <!-- <h6>Financial Lines</h6> -->
        <b-table class="subTable" small :items="row.item.financialLines" :fields="subTableColumns">
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(unitPrice)="data">
            <div style="text-align: left;">
              R {{ data.value.toLocaleString('en-US') }}
            </div>
          </template>
        </b-table>
      </template>
    </b-table>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <div>
            <b-button variant="outline-red" squared class="ml-2" @click="close">Cancel</b-button>
          </div>
          <div>
            <b-button variant="primary" squared class="ml-2" :disabled="!selectedInvoiceToExport"
              @click="exportToExisting">{{ isExporting ?
      'Exporting...' : "Export" }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// https://codesandbox.io/p/sandbox/b-table-expandcollapse-demo-onlyone-table-open-npsxt?file=%2Fsrc%2Fcomponents%2FGridTest.vue%3A63%2C7-63%2C59
import api from "../api.js";
import eventBus from '../eventBus';

export default {
  name: 'ExportedPastelInvoices',
  props: {
    invoices: Array,
    pastelInvoices: Array,
    currentTab: String
  },
  data() {
    return {
      isLoading: false,
      selectedInvoiceToExport: null,
      isExporting: false,
      selectedInvoicesToAppend: [],
      tableData: {
        resultsPerPage: 50,
        currentPage: 1,
        dataSource: [],
        isLoading: false,
        isSaving: false,
        ogTableData: [],
        tableColumns: [
          { label: '#', key: 'index', tdClass: '', sortable: false, numeric: true, html: false, visible: true },
          {
            label: "Order No.",
            key: "orderNo",
            numeric: false,
            html: false,
            visible: true,
          },
          {
            label: "Document ID",
            key: "documentID",
            numeric: false,
            html: false,
            visible: true,
          },
          {
            label: "Customer Account Code",
            key: "customerAccountCode",
            numeric: false,
            html: false,
            visible: false,
          },
          {
            label: "Supplier Account Code",
            key: "supplierAccountCode",
            numeric: false,
            html: false,
            visible: false,
          },
          {
            label: "Order Date",
            key: "orderDate",
            numeric: false,
            html: false,
            visible: true
          },
          {
            label: "Invoice Date",
            key: "invoiceDate",
            numeric: false,
            html: false,
            visible: true
          },
          { label: 'Load Count', key: 'total', tdClass: '', sortable: false, numeric: true, html: false, visible: true },
          {
            label: '',
            key: 'actions',
            sortable: false,
            tdClass: '',
            visible: true
          },
          { label: '', key: 'actions', sortable: false, tdClass: '', visible: true }
        ]
      },
      subTableColumns: [
        { label: '#', key: 'index', tdClass: '', sortable: false, numeric: true, html: false, visible: true },
        { label: 'ID', key: 'lineID' },
        { label: 'Load Confirmation No.', key: 'description' },
        { label: 'Pick Up Location', key: 'pickUpLocation' },
        { label: 'Drop Off Location', key: 'dropOffLocation' },
        { label: 'Bill On Quantity', key: 'quantity' },
        { label: 'To Process', key: 'toProcess' },
        { label: 'Unit Price', key: 'unitPrice' },
      ],
    }
  },
  mounted() {
    this.tableData.dataSource = Array.from(this.pastelInvoices);
    this.ogTableData = { ...this.tableData };
    this.toggleVisibility(this.currentTab);
    this.selectedInvoicesToAppend = this.invoices.map(item => ({
      AccountCode: "1000>010",
      TaxCode: "1",
      Description: item.orderId,
      ToProcess: item.billOnQuantity,
      Quantity: item.billOnQuantity,
      UnitPrice: item.salesPrice
    }));

    const loadIDs = this.pastelInvoices.flatMap(invoice =>
      invoice.financialLines.map(line => line.description)
    );

    api.GetOrderDetailsByIds(loadIDs)
      .then((data) => {
        const dataMap = new Map(data.map(item => [item.orderId, item]));

        this.pastelInvoices.forEach(invoice => {
          invoice.financialLines = invoice.financialLines.map(line => {
            if (dataMap.has(line.description)) {
              const matchedData = dataMap.get(line.description);
              line.pickUpLocation = matchedData.pickUpLocation;
              line.dropOffLocation = matchedData.dropOffLocation;
            }
            return line;
          });
        });
      })
      .catch((error) => {
        this.$toast.error(error);
      });
  },
  methods: {
    setVisibility(label, visibility) {
      const column = this.ogTableData.tableColumns.find(col => col.label === label);

      if (column) {
        column.visible = visibility;
        this.tableData.tableColumns = [...this.ogTableData.tableColumns.filter(col => col.visible)];
      }
    },
    handleRowClicked(item) {
      if (item.length > 0) {
        this.selectedInvoiceToExport = item[0];
        api.IsOrderInQueue(this.selectedInvoiceToExport.orderNo)
        .then((data) => {
          if (data) {
            this.selectedInvoiceToExport = null;
            this.$toast.warning('Order is still busy.');
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
      } else {
        this.selectedInvoiceToExport = null;
      }
    },
    toggleVisibility(currentTab) {
      if (currentTab == "receivable") {
        this.setVisibility("Customer Account Code", true)
        this.setVisibility("Supplier Account Code", false)
      }
      else if (currentTab == "payable") {
        this.setVisibility("Customer Account Code", false)
        this.setVisibility("Supplier Account Code", true)
      }
    },
    toggleDetails(item) {
      console.log(item)
      this.$set(item, '_showDetails', !item._showDetails);
    },
    exportToExisting() {
      this.isExporting = true;
      const request = { ...this.selectedInvoiceToExport }

      this.selectedInvoicesToAppend.forEach(invoice => {
        if (this.currentTab === 'receivable') {
          invoice.AccountCode = '1000>010';
          invoice.TaxCode = '1';
        } else if (this.currentTab === 'payable') {
          invoice.AccountCode = '2000>010';
          invoice.TaxCode = '3';
        }
      });

      request.financialLines.push(...this.selectedInvoicesToAppend);

      if (this.currentTab == "receivable") {
        api.SalesOrderPlaceOrderExisting(this.selectedInvoiceToExport, this.invoices[0].bulkOrderNumber)
          .then((results) => {
            this.$toast.success('Invoice/s exported successfully!');
            this.isExporting = false;
            this.selectedInvoicesToAppend = null;

            eventBus.$emit('closeEditExportedSuccess', results);
          })
          .catch(() => {
            this.$toast.error('Invoice/s export failed!');

            this.isExporting = false;
          });
      } else {
        api.PurchaseOrderExisting(this.selectedInvoiceToExport, this.invoices[0].bulkOrderNumber)
          .then((results) => {
            this.$toast.success('Invoice/s exported successfully!');
            this.isExporting = false;
            this.selectedInvoicesToAppend = null;

            eventBus.$emit('closeEditExportedSuccess', results);
          })
          .catch(() => {
            this.$toast.error('Invoice/s export failed!');

            this.isExporting = false;
          });
      }
    },
    formatDate(uploadDate) {
      if (!uploadDate) {
        return 'N/A';
      }

      const dateObj = new Date(uploadDate);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');

      return `${year}/${month}/${day}`;
    },
    closeSuccess() {
      eventBus.$emit('closeEditExportedSuccess', { statuses: this.tableData.dataSource });
    },
    close() {
      eventBus.$emit('closeEditExported', { statuses: this.tableData.dataSource });
    }
  }
}
</script>


<style>
.subTable td {
  padding-left: 12px !important;
}

.subTable td {
  font-size: 0.7rem !important;
}

.subTable th {
  font-size: 0.7rem !important;
}
</style>