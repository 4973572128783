<template>
  <div>
    <b-col class="p-0" v-if="exportedPastelInvoice">
      <b-row>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Document ID</label> {{ exportedPastelInvoice.exportedInvoice.id }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Order Number</label> {{ exportedPastelInvoice.exportedInvoice.reference }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>External Order Number</label> {{ exportedPastelInvoice.order.externalOrderNo }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Order Type</label> {{ exportedPastelInvoice.exportedInvoice.orderType }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Bulk Order No</label> {{ exportedPastelInvoice.exportedInvoice.bulkOrderNo }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Invoice Date</label> {{ formatDate(exportedPastelInvoice.order.invoiceDate) }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Order Date</label> {{ formatDate(exportedPastelInvoice.order.orderDate) }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Date Created</label> {{ formatDateTime(exportedPastelInvoice.exportedInvoice.createdDate)
            }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Date Modified</label> {{ formatDateTime(exportedPastelInvoice.exportedInvoice.modifiedDate)
            }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Is In Queue</label> {{ exportedPastelInvoice.exportedInvoice.isInQueue }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Is Active</label> {{ exportedPastelInvoice.exportedInvoice.isActive }}</b-row>
        </b-col>
        <b-col cols="4" class="mb-2 p-0">
          <b-row><label>Total Number of Loads</label> {{ exportedPastelInvoice.order.financialLines?.length }}</b-row>
        </b-col>
      </b-row>
    </b-col>

    <h4>Financial Lines</h4>
    <b-input v-model="searchQuery" placeholder="Search" class="mb-3"></b-input>
    <b-table striped class="subTable" v-if="exportedPastelInvoice" small :items="filteredFinancialLines"
      :fields="financialLinesColums">
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(unitPrice)="data">
        <div style="text-align: left;">
          R {{ data.value.toLocaleString('en-US') }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import api from "../api.js";
import eventBus from '../eventBus';

export default {
  name: 'ExportedPastelInvoicesReadOnly',
  props: {
    Reference: String
  },
  data() {
    return {
      isLoading: false,
      financialLines: null,
      searchQuery: '',
      exportedPastelInvoice: null,
      order: null,
      financialLinesColums: [
        { label: '#', key: 'index', tdClass: '', sortable: false, numeric: true, html: false, visible: true },
        { label: 'Line ID', key: 'lineID' },
        { label: 'Load Confirmation No.', key: 'description' },
        { label: 'Bill On Quantity', key: 'quantity' },
        { label: 'To Process', key: 'toProcess' },
        { label: 'Unit Price', key: 'unitPrice' },
      ],
    }
  },
  mounted() {
    api.GetExportedInvoiceByReference(this.Reference)
      .then((data) => {
        this.exportedPastelInvoice = data;
        console.log(data);
      })
      .catch((error) => {
        console.log(error)
      });
  },
  computed: {
    filteredFinancialLines() {
      if (!this.searchQuery) {
        return this.exportedPastelInvoice.order.financialLines;
      }
      return this.exportedPastelInvoice.order.financialLines.filter((line) => {
        return (
          line.lineID.toString().includes(this.searchQuery) ||
          line.description.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          line.quantity.toString().includes(this.searchQuery) ||
          line.toProcess.toString().includes(this.searchQuery) ||
          line.unitPrice.toString().includes(this.searchQuery)
        );
      });
    }
  },
  methods: {
    formatDateTime(dateStr) {
      const date = new Date(dateStr);

      return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    },
    formatDate(uploadDate) {
      if (!uploadDate) {
        return 'N/A';
      }

      const dateObj = new Date(uploadDate);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');

      return `${year}/${month}/${day}`;
    },
    close() {
      eventBus.$emit('closeExportedReadOnly', { statuses: this.tableData.dataSource });
    }
  }
}
</script>

<style></style>