<template>
  <div id="orderView">

    <div v-if="orderid == null">
      Please provide Order ID
    </div>
    <div v-if="orderid !== null" @dragover.prevent @drop.prevent @dragenter.self="fileDragEnd">
      <b-row @dragenter.self="fileDragEnd">
        <b-col class="p-0">
          <b-row>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Vendor Name</label> {{ order.vendorName }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Service Date</label> {{ formatDate(order.serviceDate) }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Bulk Order No.</label> {{ order.bulkOrderNumber }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Load Confirmation No.</label> {{ order.orderId }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Vehicle Reg</label> {{ order.vehicleReg }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Pick Up Location</label> {{ order.pickUpLocation }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Drop Off Location</label> {{ order.dropOffLocation }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Bill On Quantity</label> {{ order.billOnQuantity }}</b-row>
            </b-col>
            <b-col cols="4" class="mb-2 p-0">
              <b-row><label>Rate Per Ton</label> <span v-if="isAccountsReceivable()"> {{ 'R ' + order.salesPrice
                  }}</span>
                <span v-if="isAccountsPayable()"> {{ 'R ' + order.costPrice }}</span></b-row>
            </b-col>
            <b-col v-if="isAccountsReceivable()" cols="4" class="mb-2 p-0">
              <b-row><label>AR Invoice Total</label> R {{ (order.salesPrice *
      order.billOnQuantity).toLocaleString('en-US') }}</b-row>
            </b-col>
            <b-col v-if="isAccountsPayable()" cols="4" class="mb-2 p-0">
              <b-row><label>AP Invoice Total</label> R {{ (order.costPrice *
      order.billOnQuantity).toLocaleString('en-US') }}</b-row>
            </b-col>
            <b-col v-if="ogStatus != null" cols="4" class="mb-2 p-0">
              <b-row class="pr-3">
                <label>Status <span class="text-danger">*</span></label>
                <b-form-select v-model="order.status" :disabled="allowUserStatusSelect() === false">
                  <option value="Queried" v-if="allowUserStatusSelect() === true">
                    Query
                  </option>
                  <option value="Accepted" :disabled="!(ogStatus == 'Queried' || ogStatus == 'Pending')">
                    Accept
                  </option>
                  <option value="Rejected"
                    v-if="ogStatus == 'Rejected' || (ogStatus == 'Queried' || ogStatus == 'Pending') == true"
                    :disabled="!(ogStatus == 'Queried' || ogStatus == 'Pending')">
                    Reject
                  </option>
                  <option value="Pending" disabled>
                    Pending
                  </option>

                  <option v-if="ogStatus == 'AR Exported'" value="AR Exported" disabled>
                    AR Exported
                  </option>
                  <option v-if="ogStatus == 'AP Exported'" value="AP Exported" disabled>
                    AP Exported
                  </option>
                  <option v-if="ogStatus == 'AR Invoiced'" value="AR Invoiced" disabled>
                    AR Invoiced
                  </option>
                  <option v-if="ogStatus == 'AP Processed'" value="AP Processed" disabled>
                    AP Processed
                  </option>
                  <option value="AR Invoiced - AP Exported" disabled>AR Invoiced - AP Exported</option>
                  <option value="AR Exported - AP Processed" disabled>AR Exported - AP Processed</option>
                  <option value="Completed" disabled>Completed</option>
                </b-form-select>
              </b-row>
            </b-col>

            <b-col cols="4" class="p-0 pr-3">
              <label>Invoice Date <span class="text-danger">*</span></label>
              <b-form-datepicker v-model="order.invoiceDate" :disabled="ogStatus == 'Completed'"></b-form-datepicker>
            </b-col>

            <b-col cols="4" class="p-0 pr-3">
              <label>Invoice Received Date <span class="text-danger">*</span></label>
              <b-form-datepicker v-model="order.invoiceReceivedDate"
                :disabled="ogStatus == 'Completed'"></b-form-datepicker>
            </b-col>

            <b-col cols="4" class="p-0 pr-3">
              <label>Load Value Date <span class="text-danger">*</span></label>
              <b-form-datepicker v-model="order.loadDate" :disabled="ogStatus == 'Completed'"></b-form-datepicker>
            </b-col>

            <b-col cols="4" class="p-0 pr-3">
              <label>Vendor Invoice Number <span class="text-danger">*</span></label>
              <b-form-input v-model="order.invoiceNumber" :disabled="ogStatus == 'Completed'"></b-form-input>
            </b-col>

            <b-col cols="4" class="p-0 pr-3">

            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="boxes">
          <H3>{{ this.documentUrl ? "Supporting Documents" : "No Supporting Documents" }}</H3>
          <b-embed v-if="this.documentUrl" :src="this.documentUrl" type="iframe" id="iFrameSupDoc" frameBorder="0"
            scrolling="auto" height="100%" width="100%"></b-embed>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-end">
            <div>
              <b-button variant="outline-red" squared class="ml-2" :disabled="isSaving"
                @click="cancel">Cancel</b-button>
            </div>
            <div>
              <b-button variant="primary" squared class="ml-2" @click="saveInvoice" :disabled="isSaving">{{ isSaving ?
      'Saving...' : "Save" }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import api from "../api.js";
import warehouseApi from '../warehouseApi';
import eventBus from '../eventBus';

export default {
  name: "OrderViewer",
  components: {},
  props: {
    orderid: String,
    currentTab: String
  },
  mounted() {
    //
  },
  data: () => ({
    isLoading: false,
    loadDate: null,
    ogStatus: null,
    order: {},
    isSaving: false,
    user: null,
    role: null,
    activeDocument: {},
    loaded: false,
    displayUpload: false,
    showFileTypeModal: false,
    showReasonModal: false,
    query: {
      reason: "",
      status: null
    },
    upload: {
      fileType: 0,
      files: null
    },
    isDocumentDownload: false,
    documentUrl: ""
  }),
  watch: {
    'order.loadDate': function (newVal) {
      if (newVal) {
        this.onLoadDateChange(newVal);
      } else {
        this.order.salesPrice = this.order.ogSalesPrice;
        this.order.costPrice = this.order.ogCostPrice;
      }
    }
  },
  methods: {
    async onLoadDateChange(date) {
      api.GetLoadDateRate(this.order.serviceItemId, this.order.vendorId, date)
        .then((zulaRate) => {
          debugger
          if (zulaRate != null) {
            this.order.salesPrice = zulaRate.salesPrice;
            this.order.costPrice = zulaRate.costPrice;
          }

          this.order.salesPrice = this.order.salesPrice ? this.order.salesPrice : this.order.ogSalesPrice;
          this.order.costPrice = this.order.costPrice ? this.order.costPrice : this.order.ogCostPrice;

        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    labelMaker(l) {
      l = l.charAt(0).toUpperCase() + l.slice(1);
      return l.replace(/([A-Z][a-z]+)/g, ' $1 ').trim();
    },
    allowUserStatusSelect() {
      return this.ogStatus == 'Accepted' || this.ogStatus == 'Queried' || this.ogStatus == 'Pending';
    },
    formatDate(uploadDate) {
      if (!uploadDate) {
        return 'N/A';
      }

      const dateObj = new Date(uploadDate);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');

      return `${year}/${month}/${day}`;
    },
    saveInvoice() {
      this.isSaving = false;

      let request = {
        bulkOrderNumber: this.order.bulkOrderNumber,
        orderId: this.order.orderId,
        status: this.order.status,
        invoiceDate: this.order.invoiceDate,
        loadDate: this.order.loadDate,
        invoiceReceivedDate: this.order.invoiceReceivedDate,
        invoiceNumber: this.order.invoiceNumber,
        salesPrice: this.order.salesPrice,
        costPrice: this.order.costPrice,

      };

      api.SetOrderStatus(request)
        .then(() => {
          this.$toast.success('Invoice saved successfully!');
          this.isSaving = false;
          eventBus.$emit('invoiceSaved', { order: request });
        })
        .catch(() => {
          this.isSaving = false;
          this.$toast.error('Invoice could not be saved!');
        });
    },
    isAccountsReceivable() {
      return this.currentTab == "receivable";
    },
    isAccountsPayable() {
      return this.currentTab == "payable";
    },
    cancel() {
      eventBus.$emit('cancel');
    },
    onDocsClicked(f) {
      this.activeDocument = f
    },
    showAdminOrderControls() {
      return this.user.role.Name === 'LcsUser' || this.user.role.Name === 'Admin'
    },
    printError(err) {
      this.makeToast("Invoice Tracker", err, 'danger')
    },
    dragFile(e) {
      this.displayUpload = false

      this.upload.files = e.dataTransfer.files
      this.showFileTypeModal = true
    },
    uploadFile(e) {
      this.upload.files = e.target.files
      this.showFileTypeModal = true
    },
    uploadFileSelector() {
      for (const file of this.upload.files) {

        let formData = new FormData();
        formData.append("file", file);

        api.authPost.post(api.Url + '/Cdn', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }).then(
          x => {

            api.LinkSupportingDocs({
              "orderId": this.orderid,
              fileType: this.upload.fileType,
              file: { "id": x.data }
            }, console.log, this.printError)
            this.UpdateSupportingDocs()
          }
        );
      }
    },
    fileDragStart() {

      // e.dataTransfer.effectAllowed="none"
      this.displayUpload = true
    },
    fileDragEnd() {

      // e.dataTransfer.effectAllowed="none"
      this.displayUpload = false

    },
    GetInvoice() {
      var s = (response) => {
        this.isDocumentDownload = true;

        warehouseApi.getDocumentWithContent(response?.entryId)
          .then((data) => {
            this.documentUrl = `data:application/pdf;base64,${data.documentContent}`;
          })
          .catch((error) => {
            this.isDocumentDownload = false;
            console.log(error);
          });

        this.order = response;
        if (this.order.loadDate == null) {
          console.log(this.order);
          this.onLoadDateChange(this.order.serviceDate);
        }
        this.ogStatus = response.status;
      }

      api.GetOrderbyID(this.orderid, s, this.printError)
    },
    ModalSetFileType(type) {
      if (type === "invoice") {
        this.upload.fileType = 1
      } else if (type === "supportDocument") {
        this.upload.fileType = 0
      }
      this.showFileTypeModal = false
      this.uploadFileSelector()
    },
    ModalReason(action) {
      if (action === "cancel") {
        this.query.status = ""
        this.query.reason = ""
        this.showReasonModal = false
      } else if (action === "submit") {
        if (this.query.reason == "") {
          alert("Please provide a reason.")
          return
        }
        var s = x => {
          //successfull change
          console.log(x)
          this.showReasonModal = false
          alert("Status submitted.")
        }
        var e = e => {
          console.error(e)
          alert("Status not submitted.")
          this.showReasonModal = false
        }
        api.SetOrderStatus(this.orderid, this.query.status, this.query.reason, s, e)
      }
    },

    onPickFile() {
      this.$refs.fileInput.click()
    },

  },
  created() {

    // this.orderid = this.$route.query.orderid

    this.user = JSON.parse(localStorage.getItem("user_data"));
    // this.userdata.role = (localStorage.getItem("role"));


    // this.UpdateSupportingDocs()
    this.GetInvoice();
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.dataList {
  list-style-type: none;
  padding: 0px !important;
}

.boxes {
  // border: #ebebeb 3px;
  // border-style: outset;
  background-color: var(--background-primary);
  margin: 5px 0px;
  border-radius: 10px;
  padding: 5px 10px;
  overflow: clip;
  /*display: inline-flex;*/
  min-width: 250px;
}

#ControlButtonBox .boxes {
  border: #ebebeb 3px;
  // border-style: outset;
  background-color: var(--background-primary);
  // margin: 5px 10px;
  // border-radius: 10px;
  // padding: 5px 10px;
  overflow: clip;
  /*display: inline-flex;*/

  @media (min-width: 600px) {
    min-width: 110px;
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }
}

#orderView {
  // padding: 0px 20px;
}

.boxes H2 {
  // text-decoration: underline;
}

.doc-list {
  list-style: none;
  overflow: scroll;
  padding: 5px 10px !important;

}

.doc-list .btn-link {
  color: var(--text-primary) !important;


}

.doc-list .btn-link:hover {
  color: var(--text-secondary) !important;


}


iframe {
  border: 0px !important;
  /*padding-bottom: 65px;*/
}

#SupDocList {
  max-height: 350px;
  position: relative;
  min-width: 200px !important;
}

.control-button {
  text-align: center;
}

.control-button button {
  width: 90px;

}

.control-button li {
  padding: 5px 3px;
}

#ControlButtonBox {
  flex-grow: 0.4 !important;

  @media (max-width: 600px) {
    min-width: 100%;
    flex-grow: 1 !important;
  }
}

#ControlButtonBox ul {

  @media (max-width: 600px) {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.uploadable {}

.upload-display {
  position: absolute;
  background-color: rgba(29, 52, 84, 0.98);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 20% 0%;
  font-size: 3rem;


}

.upload-display i {
  font-size: 6rem;
  display: flow-root;
}


.doc-list .btn {
  display: inline-flex !important;
  text-align: center;
  font-size: 1rem !important;
}

.doc-list .btn i {

  font-size: 1rem !important;
}

.action-btn {
  display: inline-flex !important;
  //padding-right: 0.25rem;
  //padding-left: 0.25rem;
  text-align: center;
  border: 0px;
  background-color: var(--background-primary);
  width: 21px;
  padding: 0px 1px;
}

.action-btn:hover {

  background-color: red;
  border: red 2px solid;
  border-radius: 21px;
  width: 21px;
  padding: 0px 1px;
}

.action-btn i {
  font-size: 1rem !important;
}

label {}
</style>