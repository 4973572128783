<template>
  <div class="BulkEditStatus">
    <b-table striped hover sort-icon-left resizable :items="tableData.dataSource" :fields="tableData.tableColumns"
      :busy="tableData.isLoading" :per-page="tableData.resultsPerPage" :current-page="tableData.currentPage">
      <template #head(status)>
        <b-form-select v-model="selectedBulkStatus" @change="bulkStatusChange" :disabled="isLoading">
          <option value="Default" disabled>Please Select Status</option>
          <option value="Queried">Query</option>
          <option value="Accepted" :disabled="!(ogStatus.status == 'Queried' || ogStatus.status == 'Pending')">Accept</option>
          <option value="Rejected" :disabled="!(ogStatus.status == 'Queried' || ogStatus.status == 'Pending')">Reject</option>
          <option value="Pending">Pending</option>
        </b-form-select>
      </template>
      <template #cell(serviceDate)="data">
        {{ formatDate(data.value) }}
      </template>
      <!-- <template #cell(status)="data">
        <b-form-select v-model="data.item.status" :disabled="isLoading">
          <option value="Query">Query</option>
          <option value="Accepted">Accept</option>
          <option value="Denied">Deny</option>
          <option value="Pending">Pending</option>
        </b-form-select>
      </template> -->

      <!-- <template #cell(actions)="row">
        <b-row align-v="center" align-h="end">
          <b-button @click="saveInvoice(row.item)" size="sm" class="btn-icon" :disabled="isLoading">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy"
              viewBox="0 0 16 16">
              <path d="M11 2H9v3h2z" />
              <path
                d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
            </svg>
          </b-button>
        </b-row>
      </template> -->
    </b-table>

    <hr>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <div>
            <b-button variant="outline-red" squared class="ml-2" @click="close">Cancel</b-button>
          </div>
          <div>
            <b-button variant="primary" squared class="ml-2" @click="saveAllInvoices">{{ isSaving ?
      'Saving...' : "Save All" }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../api.js";
import eventBus from '../eventBus';

export default {
  name: 'BulkEditStatus',
  props: {
    invoices: Array
  },
  data() {
    return {
      isLoading: false,
      selectedBulkStatus: "Default",
      ogStatus: null,
      tableData: {
        resultsPerPage: 50,
        currentPage: 1,
        dataSource: [],
        isLoading: false,
        isSaving: false,
        tableColumns: [
          {
            label: "Service Date",
            key: "orderReceivedDate",
            numeric: false,
            html: false
          },
          {
            label: "Bulk Order No.",
            key: "bulkOrderNumber",
            numeric: false,
            html: false
          },
          {
            label: "Load Confirmation No.",
            key: "orderId",
            numeric: false,
            html: false
          },
          {
            label: "Vehicle Reg",
            key: "vehicleReg",
            numeric: false,
            html: false
          },
          {
            label: "Invoice Status",
            key: "status",
            numeric: false,
            html: false
          },
          {
            label: '',
            key: 'actions',
            sortable: false,
            tdClass: ''
          }
        ]
      }
    }
  },
  mounted() {
    this.tableData.dataSource = Array.from(this.invoices);

    this.ogStatus = {...this.tableData.dataSource[0]};
  },
  methods: {
    bulkStatusChange() {
      this.tableData.dataSource = this.tableData.dataSource.map(invoice => {
        return { ...invoice, status: this.selectedBulkStatus };
      });
    },
    formatDate(uploadDate) {
      if (!uploadDate) {
        return 'N/A';
      }

      const dateObj = new Date(uploadDate);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      
      return `${year}/${month}/${day}`;
    },
    close() {
      eventBus.$emit('closeEditStatus', { statuses: this.tableData.dataSource });
    },
    onStatusChange(newStatus, item) {
      item.status = newStatus;
      console.log("New status:", newStatus, "for item:", item);
    },
    saveInvoice(item) {
      console.log(item)

      this.isLoading = false;

      api.SetOrderStatus(item)
        .then(() => {
          this.invoices.forEach(invoice => {
            if (invoice.orderId == item.orderId) {
              invoice.someProperty = item.status;
            }
          });

          this.$toast.success('Invoice saved successfully!');
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error('Invoice could not be saved!');

          console.log(error);
        });
    },
    saveAllInvoices() {
      console.log(this.tableData.dataSource)
      api.SetOrderStatuses(this.tableData.dataSource)
        .then(() => {
          this.$toast.success('Invoice Statuses saved successfully!');
          this.isLoading = false;
          eventBus.$emit('statusesSaved', { statuses: this.tableData.dataSource });

        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error('Invoice Statuses could not be saved!');

          console.log(error);
        });
    }
  }
}
</script>


<style></style>